<template>
  <v-container fluid class="grey lighten-4">
    <v-row justify="center">
      <v-col>
        <v-card-title> Dodaj fakturę </v-card-title>

        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="6">
            <v-form ref="form" v-model="valid" lazy="true">
              <div>
                <v-row align="center" justify="center">
                  <v-col cols="1">
                    <v-icon>{{ icons.mdiCashMultiple }}</v-icon>
                  </v-col>
                  <v-col>
                    <h3 class="grey--text text--darken-2">Płatność</h3>
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="user"
                      :items="items"
                      :loading="isLoading"
                      :search-input.sync="search"
                      color="black"
                      hide-no-data
                      hide-selected
                      item-text="Description"
                      item-value="API"
                      label="Użytkownik"
                      placeholder="Wpisz dane użytkownika"
                      prepend-icon="mdi-database-search"
                      return-object
                      @click="findUser"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="number"
                      :rules="rulesCM"
                      label="Numer faktury"
                      hint="wprowadź numer faktury"
                      name="price"
                      type="text"
                      prepend-icon="mdi-file-document"
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="price"
                      :rules="rulesCM"
                      label="Kwota do zapłaty"
                      hint="podaj wartość w złotówkach"
                      name="price"
                      type="number"
                      prepend-icon="mdi-cash-multiple"
                      min="0"
                    />
                  </v-col>

                  <!-- <v-col cols="12">
                      <v-select
                        :items="['8%', '23%']"
                        label="Długość podatku VAT"
                        prepend-icon="mdi-percent"
                        v-model="tax"
                        :rules="rulesReq"
                      ></v-select>
                    </v-col> -->

                  <v-col cols="12">
                    <p>Termin płatności</p>
                    <div class="text-center">
                      <v-date-picker
                        v-model="date"
                        color="amber darken-1"
                        :first-day-of-week="1"
                        locale="pl-PL"
                      ></v-date-picker>
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <p>Załącz pliki</p>
                    <vue-dropzone
                      ref="myVueDropzone"
                      id="dropzone"
                      :options="dropzoneOptions"
                      v-on:vdropzone-sending="onSend"
                      v-on:vdropzone-success="onSuccess"
                    ></vue-dropzone>
                  </v-col>
                </v-row>
                <v-row>
                  <v-spacer />
                  <v-col cols="12" sm="3">
                    <v-btn
                      block
                      @click="addInvoice()"
                      :disabled="!valid || !user.id"
                      color="amber accent-3"
                      class="grey--text text--darken-4"
                      :x-large="true"
                      v-tilt
                      >Dodaj fakturę</v-btn
                    >
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import axios from 'axios';
  import vue2Dropzone from 'vue2-dropzone';
  import 'vue2-dropzone/dist/vue2Dropzone.min.css';

  import { mdiCashMultiple } from '@mdi/js';

  export default {
    props: {
      source: String,
    },
    components: {
      vueDropzone: vue2Dropzone,
    },
    data: () => ({
      icons: {
        mdiCashMultiple,
      },
      entries: [],
      isLoading: false,
      search: null,
      user: {},
      price: null,
      //tax: '23%',
      invoice: null,
      number: null,
      date: new Date().toISOString().substr(0, 10),
      valid: true,
      rulesCM: [
        v => !!v || 'Pole jest wymagane',
        v => /[0-9]/.test(v) || 'Wprowadź wartość w centrymetrach',
      ],
      rulesReq: [v => !!v || 'Pole jest wymagane'],
      dropzoneOptions: {
        url: `/api/media/upload`,
        thumbnailWidth: 150,
        maxFilesize: 32,
        dictDefaultMessage: 'Prześlij fakturę',
        accept: function (file, done) {
          done();
        },
      },
      mediaIds: [],
    }),
    computed: {
      fields() {
        if (!this.model) return [];

        return Object.keys(this.model).map(key => {
          return {
            key,
            value: this.model[key] || 'n/a',
          };
        });
      },
      items() {
        return this.entries.map(entry => {
          const Description = `${entry.name} ${entry.surname} (${entry.email})`;
          return Object.assign({}, entry, { Description });
        });
      },
    },
    watch: {
      search() {
        // Items have already been loaded
        if (this.items.length > 0) return;

        // Items have already been requested
        if (this.isLoading) return;

        this.isLoading = true;

        axios({
          url: this.$store.state.url + '/api/users/get/customers',
          data: {
            token: this.$store.state.token,
          },
          method: 'POST',
        })
          .then(resp => {
            this.count = resp.data.users.length;
            this.entries = resp.data.users;
          })
          .catch()
          .finally(() => (this.isLoading = false));
      },
    },
    methods: {
      onSend: function (file, xhr, formData) {
        formData.append('token', this.$store.state.token);
      },
      onSuccess: function (file, response) {
        this.mediaIds.push(response.mediaId);
      },
      addInvoice: function () {
        axios({
          url: this.$store.state.url + '/api/invoice/create',
          data: {
            token: this.$store.state.token,
            user_id: this.user.id,
            price: parseFloat(this.price),
            //vat: parseInt(this.tax),
            date_expected: this.date,
            invoice_number: this.number,
            invoice_url: this.mediaIds[0],
          },
          method: 'POST',
        })
          .then(() => {
            //put here correct redirection after payment adding
            this.$router.push({
              name: 'allInvoices',
              params: {
                id: this.user.id,
              },
            });
          })
          .catch();
      },
      findUser: function () {
        if (this.search === null) this.search = '';
      },
    },
    created() {
      this.dropzoneOptions.url = `${this.$store.state.url}/api/media/upload`;
    },
  };
</script>
